.sh1 {
  font-weight: 300;
  letter-spacing: -0.14rem;
  color: $color-white;
  font-size: 5.5rem;
  line-height: (60 / 55) * 5.5rem;

  @include smartphone-down {
    line-height: 1.2em;
  }
}

.mh1 {
  font-family: 'Migra';
  font-weight: 500;
  color: $color-grey;
  font-size: 5.8rem;
  letter-spacing: 0.15rem;
  line-height: (63 / 58) * 5.8rem;

  @include smartphone-down {
    line-height: 1.2em;
  }
}

.sh2 {
  font-weight: 300;
  color: $color-white;
  font-size: 3.6rem;
  letter-spacing: -0.07rem;
  line-height: (45 / 36) * 3.6rem;

  @include smartphone-down {
    font-size: 3rem;
    line-height: 1.2em;
  }
}

.mh2 {
  font-family: 'Migra';
  font-weight: 400;
  color: $color-grey;
  font-size: 3.9rem;
  letter-spacing: 0.12rem;
  line-height: (48 / 39) * 3.9rem;

  @include smartphone-down {
    font-size: 3.2rem;
    line-height: 1.2em;
  }
}

.sh2footer {
  font-weight: 300;
  color: $color-white;
  font-size: 4.8rem;
  letter-spacing: -0.14rem;
  line-height: 110%;

  @include smartphone-down {
    text-align: center;
  }
}

.mh2footer {
  font-family: 'Migra';
  font-weight: 400;
  color: $color-grey;
  font-size: 5.2rem;
  letter-spacing: 0.15rem;
  line-height: 110%;

  @include smartphone-down {
    text-align: center;
  }
}

.sh3 {
  font-weight: 300;
  color: $color-white;
  font-size: 2.8rem;
  letter-spacing: -0.06rem;
  line-height: (35 / 28) * 2.8rem;
  @include smartphone-down {
    line-height: 1.2em;
  }
}

.sh4 {
  font-weight: 300;
  color: $color-white;
  font-size: 2.4rem;
  letter-spacing: -0.04rem;
  line-height: (30 / 24) * 2.4rem;
  @include smartphone-down {
    line-height: 1.2em;
  }
}

.subtitle {
  font-weight: 300;
  color: $color-grey;
  font-size: 2rem;
  letter-spacing: -0.05rem;
  line-height: (22 / 20) * 2rem;
}

.subtitleM {
  font-family: 'Migra';
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 125%;
  /* or 26px */
  letter-spacing: 1px;
}

// Already defined in _body.scss
.body {
  font-size: 1.4rem;
  font-weight: 400;
  color: $color-grey;
  letter-spacing: -0.05rem;
  line-height: (22 / 14) * 1.4rem;

  @include smartphone-down {
    font-size: 11px;
  }
}

.small {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: (18 / 12) * 1.2rem;

  @include smartphone-down {
    font-size: 11px;
    line-height: 1.4em;
  }
}
