.appear {
  opacity: 0;
  transition: opacity 0.8s linear;

  &.show {
    opacity: 1;
  }
}

.appearFromBottom {
  opacity: 0;
  transform: translateY(20px);
  transition: transform 1.6s $easeOutExpo 0.8s, opacity 0.8s linear;

  &.show {
    opacity: 1;
    transform: none;
    transition: transform 1.6s $easeOutExpo, opacity 0.8s linear;
  }
}

.appearBounce {
  opacity: 0;
  transform: scale(0.9);
  transition: transform 1s $easeOutBounce 0.8s, opacity 0.8s linear;

  &.show {
    opacity: 1;
    transform: none;
    transition: transform 1.4s $easeOutBounce, opacity 0.6s linear;
  }
}

.appearScale {
  opacity: 0;
  transform: scale(0.8);
  transition: transform 1.6s $easeOutExpo 0.8s, opacity 0.8s linear;

  &.show {
    opacity: 1;
    transform: none;
    transition: transform 1.6s $easeOutExpo, opacity 0.8s linear;
  }
}

.delay1 {
  transition-delay: 0.2s !important;
}

.delay2 {
  transition-delay: 0.4s !important;
}

.delay3 {
  transition-delay: 0.6s !important;
}
