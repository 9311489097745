// Inter
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

@font-face {
  font-family: 'Migra';
  font-weight: 400;
  src: url('/fonts/migra/Migra-Regular.woff2') format('woff2'); // medium on the figma
}

@font-face {
  font-family: 'Migra';
  font-weight: 500;
  src: url('/fonts/migra/Migra-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Migra';
  font-weight: 800;
  src: url('/fonts/migra/Migra-Extrabold.woff2') format('woff2'),
    url('/fonts/migra/Migra-Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'Migra';
  font-weight: 200;
  src: url('/fonts/migra/Migra-Extralight.woff2') format('woff2'),
    url('/fonts/migra/Migra-Extralight.woff') format('woff');
}
