html {
  font-size: 62.5%;

  @media only screen and (max-width: $container-w) {
    font-size: 55.5%;
  }

  @media only screen and (max-width: $tablet) {
    font-size: 48.5%;
  }

  @include smartphone-down() {
    font-size: 41.5%;
    overflow-x: hidden;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  // @include smartphone-down() {
  //   overflow-x: hidden;
  // }
}

body {
  font-size: 1.4rem;
  font-weight: 400;
  color: $color-grey;
  letter-spacing: -0.05rem;
  line-height: (22 / 14) * 1.4rem;
  background-color: $color-bg-sphere-init;
  transition: background-color 2s ease;

  &.introInit {
    background-color: $color-grey-800;
  }

  @include smartphone-down {
    font-size: 11px;
    line-height: 1.4em;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.dg.ac {
  z-index: 9000 !important;
}

.MuiAutocomplete-popper *,
.MuiOutlinedInput-root.MuiInputBase-sizeSmall input.MuiAutocomplete-input {
  font-family: 'Inter' !important;
}
.MuiAutocomplete-popper * {
  font-size: 14px !important;
}

.MuiTooltip-popper *,
.MuiBox-root,
.MuiModal-root *,
.MuiList-root *,
.MuiInputBase-input {
  font-size: '12px';
  font-family: 'Inter';
}

// @use 'styles/_colors.scss'; // assuming a styles directory under src/
// SASS_PATH=path1;path2;path3
